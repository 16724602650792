import fetch from 'auth/FetchInterceptor'

const sessionService = {}

sessionService.getQuestions = function ({sessionId}) {
  return fetch({
    url: `/session/${sessionId}/questions`,
    method: 'get',
    params: {},
    cache: true
  })
}

sessionService.getAnswers = function ({sessionId}) {
  return fetch({
    url: `/session/${sessionId}/answers`,
    method: 'get',
    params: {},
    cache: true
  })
}

sessionService.getSections = function ({sessionId}) {
  return fetch({
    url: `/session/${sessionId}/sections`,
    method: 'get',
    params: {},
    cache: true
  })
}

sessionService.postQuestion = function (data) {
  return fetch({
    url: `/question/${data.examId}/${data.sessionId}`,
    method: 'post',
    data,
    cache: false
  })
}

sessionService.postSection = function (data) {
  return fetch({
    url: `/section/${data.sessionId}`,
    method: 'post',
    data,
    cache: false
  })
}

sessionService.createSession = function (data) {
  return fetch({
    url: `/session/${data.examId}`,
    method: 'post',
    data,
    cache: false
  })
}

sessionService.getSession = function ({examId, sessionId}) {
  return fetch({
      url: `/session/${examId}/${sessionId}`,
      method: 'get',
      params: {},
      cache: true
  })
}


sessionService.getQuestionVariant = function({sessionId, questionId}) {
  return fetch({
    url: `/question/${sessionId}/${questionId}/variant`,
    method: 'get',
    params: {},
    cache: true
  })
}

sessionService.getQuestionGuidance = function({sessionId, questionId}) {
  return fetch({
    url: `/question/${sessionId}/${questionId}/guidance`,
    method: 'get',
    params: {},
    cache: true
  })
}

export default sessionService